fieldset.requires {
	legend {
		font-size: 14pt;
	}

	ul {
		padding: 0px;
		margin: 0px;

		li {
			&:before {
				display: inline-block;
				font-family: 'Ionicons';
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				font-size: 24pt;
				line-height: 32pt;
				margin-right: 8px;
				vertical-align: middle;
				margin-top: -4px;
			}

			&:not(.checked):before {
				content: '\f401';
			}

			&.checked:before {
				content: '\f3fe';
			}
		}
	}
}
