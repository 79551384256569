fieldset {
	margin: 25px 0px -25px 0px;

	@media screen and (max-width: 480px) {
		margin: 15px 0px 0px 0px;
		padding: 0px 3vw;
		width: 96vw;
	}

	border: 0;
	padding: 20px;

	&.form {
		border: 0;
		margin: 0;
		padding: 0;
	}
}

legend {
	margin: 0px -10px;
	padding: 0px 10px;
	color: #666;
	font-size: 10pt;

	@media screen and (max-width: 480px) {
		font-size: 10pt;
	}
}

.aligned-text {
	padding: 10px;
	margin-top: -20px;
}

md-switch {
	[ng-transclude] span {
		white-space: pre-wrap;
		max-width: 75vw;
		display: inline-block;
	}
}

md-input-container .md-errors-spacer {
	display: none;
}

md-card {
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
	border: 1px solid rgba(0, 0, 0, 0.09);

	md-card-title {
		text-transform: uppercase;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 0;

		md-switch {
			padding: 0px;
			margin: 0px;
		}
	}

	md-card-content {
		flex-grow: 1;
	}

	&.flat-card {
		background: transparent;
		box-shadow: none;
		border: 0px;

		> md-card-title {
			color: #666 !important;
			font-size: 12pt;
		}
	}
}
