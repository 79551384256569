/* stylelint-disable -- this file can have hardcoded values */

$uz-font-family: roboto;
$uz-font-size-s: 12px;
$uz-font-size: 16px;
$uz-font-size-l: 20px;
$uz-font-size-xl: 24px;
$uz-font-weight: 400;
$uz-font-weight-semibold: 500;
$uz-line-height: 24px;
$uz-letter-spacing: 0.5px;
$uz-text-align: left;
