md-dialog {
	&.fullscreen,
	&.full-screen-dialog {
		width: 100vw;
		height: 100vh;
		max-width: unset;
		max-height: unset;
	}

	> md-toolbar {
		h2 {
			color: white;
		}

		.md-toolbar-tools {
			//TODO remove i
			i {
				font-size: 36px;
				margin-right: 20px;
			}

			ion-icon {
				padding-right: 8px;
			}

			h3 {
				text-transform: uppercase;
			}
		}
	}

	md-dialog-content {
		flex-grow: 1;
	}

	md-dialog-actions {
		border-top: 1px solid #eee;
	}

	&.window-dialog {
		width: 80%;
		max-width: 1000px;
		.window-dialog-content {
			padding: 0px;
			width: 100%;
			height: 100%;
		}
	}
}
