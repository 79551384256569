.open-menu-button {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 10;
	color: white;

	height: 50px !important;
}

main {
	margin-left: 56px;
}

.menu-button {
	box-shadow: none;
	border-radius: 0px;
	height: 8vh;
	min-height: 55px;
	max-height: 65px;
	margin: 0px;
	padding: 0px 10px;
	text-align: left;
	text-transform: none;
	min-width: 0px;
	width: 100%;

	.right-arrow {
		position: absolute;
		right: 10px;
		transition: opacity 0.1s linear 0.1s;
	}

	&.md-primary {
		border-radius: 0px !important;
		border-style: solid;
	}

	> span.icon {
		width: 35px;
		display: inline-block;
		margin-right: 10px;
		font-size: 20pt;
		vertical-align: middle;
		text-align: center;
	}

	&:not(.md-primary) {
		color: white !important;

		* {
			color: #666;
		}
	}
	&.md-raised * {
		color: white;
	}

	&.back-button {
		height: 50px;
		max-height: inherit;
		min-height: 50px;
		background: #eee;
		margin-top: -1px;
	}
}

/* hide menu in all cases when on public screens */
.cover menu {
	display: none !important;
}

menu {
	padding: 0px;
	margin: 0px;
	display: block;
	overflow: inherit !important;
	position: relative;

	.uz-logo {
		height: 50px;
	}

	md-sidenav {
		overflow: hidden;
		transition: all 0.2s ease-in-out;
		position: absolute;

		&.small {
			width: 55px;
			overflow: hidden;

			.hide-small {
				opacity: 0;
				transition: opacity 0s;
			}
		}

		&:not(.small) {
			.show-small {
				display: none;
			}
		}

		md-content {
			overflow-x: hidden;

			&::-webkit-scrollbar {
				width: 0 !important;
			}
		}

		.platform-name {
			text-align: center;
			height: 50px;
			line-height: 50px;
			font-size: 16pt;
			font-weight: 500;
		}

		.top-button {
			max-height: 50px;
			min-height: 50px;
			height: 50px;
			padding: 0;
			margin: 0;
			min-width: auto;
			text-align: center;
			border-radius: 0px;
		}

		&:not(.small) {
			box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.2);
		}

		.logo {
			height: 50px;
			width: 100%;
			background-size: 80% 80%;
			background-repeat: no-repeat;
			background-position: center center;
		}

		.menu-label {
			position: relative;
		}

		.pill {
			background: #f44336;
			border-radius: 4px;
			text-align: center;
			color: white !important;
			font-weight: bold;
			position: absolute;
			left: -6px;
			top: -16px;
			padding: 0px 4px;
			font-size: 6pt;
			line-height: 15px;
			transition-property: transform, top, left;
			transition-duration: 0.15s;
			transition-delay: 0.05s, 0s, 0.05s;
			transform: translateX(-100%) scale(1);
		}

		&:not(.small) .pill {
			left: calc(100% + 16px);
			top: 0px;
			transition-duration: 0.2s;
			transition-delay: 0s, 0.1s, 0s;
			transform: translateX(0px) scale(1.5);
		}

		.badge {
			background: #f44336;
			border-radius: 100%;
			text-align: center;
			color: white !important;
			font-weight: bold;
			margin-left: 8px;
			margin-top: 0px;
			position: absolute;
			left: 25px;
			font-size: 8pt;
			height: 15px;
			width: 15px;
			line-height: 15px;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
				0 3px 6px rgba(0, 0, 0, 0.23);

			.plus {
				line-height: 0px;
				font-size: 10px;
				color: red;
				position: absolute;
			}
		}

		.platform-picture {
			width: 100%;
			height: 50px;
			object-fit: contain;
			background: none !important;
		}

		.md-menu {
			padding: 0px;

			> button {
				padding: 0px 0px !important;
				width: 100%;
				text-align: center;
				min-width: 0px;
				height: 50px;

				> i {
					font-size: 24pt;
				}
			}
		}

		img.logo {
			width: 250px;
			height: 50px;
			object-fit: contain;
			background: white;
		}

		.profile-box {
			socket-status {
				position: absolute;
				margin-top: 32px;
				margin-left: 32px;
				z-index: 99;
			}

			.profile-image {
				width: 100%;

				color: #666 !important;
				box-shadow: none;
				border-radius: 0px;
				min-height: 55px;
				max-height: 65px;
				margin: 0px;
				padding: 0px 10px;
				text-align: left;
				text-transform: none;
				width: 100%;

				avatar {
					margin-left: 5px;
					margin-right: 15px;

					md-icon {
						padding: 0;
					}
				}
			}
		}
	}
}
