/* stylelint-disable declaration-no-important -- Using important is allowed, but only for overrides of 3rd party libraries */

.mdc-label {
	font-size: $uz-font-size !important;

	// By default, labels are too small
}

mat-radio-group {
	// By default, radio have a padding for ripple.
	// We don't want to include it in design, in order, for instance to use isolated radio next to a border
	gap: $size-spacing-m;
	display: flex;
	align-items: center;

	.mdc-radio {
		padding: 0 !important;
	}

	mat-radio-button {
		.mdc-label {
			margin-left: $size-spacing-s;
		}
	}
}

mat-slide-toggle {
	.mat-internal-form-field {
		display: flex;
		gap: $size-spacing-s;
	}
}

mat-checkbox {
	// By default, checkbox have a padding for ripple.
	// We don't want to include it in design, in order, for instance to use isolated checkbox next to a border

	.mat-internal-form-field {
		display: flex;
		gap: $size-spacing-s;
	}

	.mdc-checkbox {
		padding: 0 !important;

		&__background {
			left: 0 !important;
			top: 0 !important;
		}

		&__ripple {
			width: 40px; /* stylelint-disable-line -- 40px is from material design (not our design system), and, we don't want to include it inside */
			height: 40px; /* stylelint-disable-line -- 40px is from material design (not our design system), and, we don't want to include it inside */
			transform: translate(-50%, -50%);
			left: 50%;
			top: 50%;
		}
	}
}

.mdc-slider__value-indicator {
	width: unset !important; // We don't want to limit the size of slider tooltips
	white-space: nowrap;
	overflow-wrap: unset;
}
