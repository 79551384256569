md-progress-linear[show-if-loading] {
	position: absolute;
	top: 0px;
	z-index: 10000;
	width: 100vw;
}

.expiry-banner {
	padding: 10px;
	background-color: #223641;
	color: white;
	font-weight: bold;
}

.card-sub-header{
    padding: 24px 16px 16px;
	color:gray;
	margin-top:-35px;
}