[md-color-picker] .md-color-picker-input-container .md-color-picker-clear,
md-color-picker .md-color-picker-input-container .md-color-picker-clear {
	bottom: 15px !important;
}

input[placeholder] {
	text-overflow: ellipsis;
}
::-moz-placeholder {
	text-overflow: ellipsis;
} /* firefox 19+ */
input:-moz-placeholder {
	text-overflow: ellipsis;
}

ion-icon {
	width: 24px;
	height: 24px;
	vertical-align: middle;
}

md-chips-wrap > div:first-child {
	width: 100%;

	input {
		width: 100%;
	}
}

md-tab-data {
	opacity: 1;
	display: table;
	right: 0;
	left: initial;
	z-index: 20;
}

md-checkbox {
	margin-top: 8px;
	margin-bottom: 8px;
}

i.ion-ios-help {
	display: inline-block;
	margin-left: 10px;
	font-size: 18pt;
	cursor: help;
}

main > ui-view {
	md-toolbar > span:first-child {
		transform: translateX(0px);
		transition: all 0.5s ease-in-out;
	}

	&.ng-enter {
		md-toolbar > span:first-child {
			transform: translateX(-300px);
		}
	}

	&.ng-enter-active {
		md-toolbar > span:first-child {
			transform: translateX(-300px);
		}
	}
}

.logo {
	background-size: contain;
	background-image: url('images/logo.svg');
	background-repeat: no-repeat;
	background-position: center center;
	margin: auto;
	height: 6vh;
}

body.sandbox {
	&:before {
		content: 'SANDBOX';
		display: block;
		background: red;
		color: white;
		z-index: 10;
		font-size: 10pt;
		padding: 4px;
		font-weight: 900;
		text-align: center;
	}
}

h1,
h2,
h3 {
	font-weight: 300;
}

* {
	outline: none;
}

a {
	color: #0291a3;
	cursor: pointer;
}

.inline-span {
	display: flex;
	flex-direction: column;
	margin: 10px;
	justify-content: center;
	padding-bottom: 20px;
}

loader {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 9999;
	top: 0;
	border: 0;
	left: 0;
	right: 0;
	background-color: #000;
	opacity: 0.5;

	> md-progress-circular {
		position: relative;
		height: 100%;
		top: 40%;
		margin: 0 auto;
	}
}

md-tooltip {
	display: block;
	max-width: 400px !important;
	height: auto !important;
	word-wrap: break-word !important;
	padding: 0.5em 0.5em;
	background: #333 !important;
	opacity: 1 !important;
	font-size: 13px !important;
	overflow: visible !important;
	white-space: normal !important;

	._md-content {
		height: auto !important;
		max-width: 400px !important;
		font-size: 13px !important;
	}
}

.map-marker-tooltip,
.gm-style-iw {
	width: auto !important;
	height: auto !important;
	word-wrap: break-word !important;
	padding: 0.5em 0.5em !important;
	background: #333 !important;
	border-color: #333 !important;
	opacity: 1 !important;
	font-size: 13px !important;
	overflow: visible !important;
	border-radius: 5px !important;
	color: #eee !important;
	font-family: Roboto, Helvetica Neue, sans-serif !important;
	font-weight: 500 !important;
	&:before {
		border-top-color: #333 !important;
	}
}

.gm-style-iw-t {
	.gm-style-iw-d {
		overflow: hidden !important;
	}

	div:last-child:after {
		background: #333 !important;
		z-index: -20 !important;
	}
}
md-toolbar-divider {
	height: 50px;
	background: rgba(0, 0, 0, 0.2);
	width: 1px;
	margin: 0px 10px;
	display: block;

	@media screen and (max-width: 600px) {
		display: none;
	}
}

md-toolbar {
	::-webkit-input-placeholder {
		color: white;
	}
	&:focus {
		::-webkit-input-placeholder {
			color: #666;
		}
	}
	button.md-button {
		@media screen and (max-width: 960px) {
			min-width: 0px;
			span {
				display: none;
			}
		}
		&:disabled * {
			color: rgba(0, 0, 0, 0.3) !important;
		}
	}
}

md-toast.md-center {
	left: 50%;
	transform: translate3d(-50%, 0, 0);
}

.md-button {
	&.md-icon-button {
		> i {
			margin-top: -4px;
			display: inline-block;
			margin-left: -1px;
		}
	}
	> i {
		font-size: 24pt;
		vertical-align: middle;

		+ span {
			padding-left: 4px;
		}
	}

	md-icon {
		vertical-align: middle !important;
		padding-bottom: 9px;
		padding-right: 3px;
	}
}

.parcel-status {
	white-space: nowrap;
}

.break-duration {
	white-space: nowrap;
	color: #515b5f;
	&:before {
		content: '\f403';
		display: inline-block;
		font-family: 'Ionicons';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		text-rendering: auto;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		padding-right: 4px;
		top: 2px;
		/*position: relative; // Warning ! Broke responsive on retina monitor */
	}
}

.picture {
	background: url('images/grid.png');

	img {
		width: 100%;
	}
}

div.multiline {
	white-space: pre-wrap;
	font-size: 10pt;
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}

#error-popup-message {
	color: #ff4081;
}

.button-block {
	display: block;
	width: 100%;
}

.center-text {
	text-align: center !important;
}

md-sidenav.detail-window {
	display: flex;
	flex-direction: column;
	min-width: 30%;
	max-width: 80%;
	width: inherit;

	> md-toolbar + * {
		flex-grow: 1;
		overflow: auto;
	}
}

.md-subheader {
	> * {
		padding-left: 0px !important;
	}
}

.panel {
	md-toolbar {
		border: 4px solid lightgrey;
		border-bottom: 0;
	}
	md-content {
		border: 4px solid lightgrey;
		border-top: 0;
	}
}

dl {
	padding: 10px;
	margin: 0px;

	dt {
		color: #666;
	}

	&:not(:last-child) {
		border-bottom: 1px solid #eee;
	}
}

md-list[md-list-compact] md-list-item:before {
	min-height: 0px;
}

md-sidenav {
	position: fixed;
}

md-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.block-loading {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	.block-loading-loader {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
	}
	.block-loading-content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
}

i.icon-left {
	margin-right: 10px;
	font-size: 24pt;
	vertical-align: middle;
	width: 25px;
	display: inline-block;
	text-align: center;
}

no-results {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 18pt;
	color: #666;
	background: white;

	//TOOD: remove when ioniconsv2 is completely removed
	i {
		font-size: 48pt;
		margin-bottom: 24pt;
		display: inline-block;
		color: #666;
	}

	ion-icon {
		width: 48pt;
		height: 48pt;
		margin-bottom: 24pt;
		display: inline-block;
		color: #666;
	}
}

.table {
	display: table;
	> * {
		display: table-row;
		> * {
			display: table-cell;
		}
	}
	&.table-padding {
		> * > * {
			padding: 5px 10px;
		}
	}
}

.container-small {
	max-width: 10em;
	margin-left: auto;
	margin-right: auto;
}

.container-medium {
	max-width: 30em;
	margin-left: auto;
	margin-right: auto;
}

.container-large {
	max-width: 60em;
	margin-left: auto;
	margin-right: auto;
}

.container-full {
	max-width: none;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.box-grid {
	background: url('images/box-grid.png') right bottom no-repeat;
}

.text-small {
	th,
	td {
		font-size: 10pt;
		padding: 8px 8px;
		white-space: nowrap;
	}
}

.text-under {
	font-size: 9pt;
	color: #999;
}

.text-light {
	color: #9e9e9d;
}

.appear {
	opacity: 1;
	&.ng-hide-add.ng-hide-add-active,
	&.ng-hide-remove.ng-hide-remove-active {
		transition: all linear 0.5s;
	}
	&.ng-hide {
		opacity: 0;
	}
}

body {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	min-height: 100%;
	overflow: hidden;

	main {
		flex-grow: 1;
		background: #fff;
	}
}

#nav-tabs {
	width: 100%;
}

p.info,
p.infoleft {
	line-height: 1.8;
	font-size: 11pt;
	color: #666;
}

p.infoleft {
	text-align: left;
}

p.error {
	line-height: 18px;
	height: 18px;
	color: #f44336;
	font-size: 10pt;
	&:before {
		content: ' ';
		margin-right: 10px;
		width: 14px;
		height: 14px;
		line-height: 10px;
		display: inline-block;
		color: white;
		background: #980f00;
		border-radius: 100%;
		vertical-align: sub;
	}
}

p.warning {
	line-height: 18px;
	height: 18px;
	color: #666;
	font-size: 10pt;
	&:before {
		content: ' ';
		margin-right: 10px;
		width: 14px;
		height: 14px;
		line-height: 10px;
		display: inline-block;
		color: white;
		background: #ffc107;
		border-radius: 100%;
		vertical-align: sub;
	}
}

.icon-add,
.icon-remove {
	font-size: 1.5em;
}

.cap-this::first-letter {
	text-transform: uppercase;
}

screen-content {
	h1,
	p,
	li {
		color: #666;
	}

	ol {
		li {
			margin: 10px 0px;
		}
	}
}

.devops {
	font-family: Courier;
	background: white;
	padding: 10px;
	border: 1px solid #eee;
	color: #666;
	font-size: 11pt;
}

.unselectable {
	user-select: none;
}

.selectdemoSelectHeader {
	/* Please note: All these selectors are only applied to children of elements with the 'selectdemoSelectHeader' class */
}
.selectdemoSelectHeader .demo-header-searchbox {
	border: none;
	outline: none;
	height: 100%;
	width: 100%;
	padding: 0;
}
.selectdemoSelectHeader .demo-select-header {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14),
		0 0 0 0 rgba(0, 0, 0, 0.12);
	padding-left: 10.667px;
	height: 48px;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	width: auto;
}
.selectdemoSelectHeader md-content._md {
	max-height: 240px;
}

/**Some overrides for stupid leaflet stuff */
.leaflet-pane {
	z-index: 19 !important;
}
.leaflet-top,
.leaflet-bottom {
	z-index: 20 !important;
}
.leaflet-popup-content {
	min-width: 300px !important;
	overflow: auto;
}

.no-margin {
	margin: 0px !important;
}

.no-wrap {
	white-space: nowrap;
}

.md-button:not(.md-icon-button):not(.md-fab) {
	ion-icon {
		margin-right: 4px;
	}
}
