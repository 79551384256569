/* Fix workaround https://github.com/angular/material/issues/9429 */
md-pagination-wrapper {
	width: auto !important;
}

md-tabs {
	&.full-tabs,
	&.tabs-full,
	&[tabs-full] {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		> md-tabs-content-wrapper {
			flex-grow: 1;
			display: flex;

			> md-tab-content {
				flex-grow: 1;
				display: flex;

				> div {
					> section {
						height: 100vh;
						display: flex;
						overflow: auto;
					}
					flex-grow: 1;
					display: flex;
					flex-direction: column;
				}
			}
		}
	}

	&[tab-padding] md-tab-content {
		padding: 30px;
	}

	&.hide-tabs > md-tabs-wrapper {
		display: none;
	}

	&.inner-tabs,
	&[tabs-inner] {
		margin: 8px;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
		border: 1px solid rgba(0, 0, 0, 0.09);
		box-sizing: border-box;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
			0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);

		md-tab-content {
			background: white;
			padding: 16px;
		}
	}
}
