.custom-color-picker-wrapper {
	position: relative;

	&.ignore-click {
		pointer-events: none;
	}

  .custom-color-picker {
		display: none;
	}

  .custom-color-picker-label {
		cursor: pointer;
		width: 24px;
		height: 24px;
		border: 2px solid #fff;
		border-radius: 50%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14),
			0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
		display: inline-block;
		position: absolute;
	}

  .custom-color-picker-label.disabled:hover {
		cursor: default;
	}
}