/* stylelint-disable declaration-no-important -- Using important is allowed, but only for overrides of 3rd party libraries */

// Override the default dialog styles that we don't want
@import '../variables/semantic-tokens';

html {
	--mdc-dialog-supporting-text-color: #{$text-on-light-primary};
	--mat-dialog-content-padding: #{$size-spacing-l};
	--mat-dialog-with-actions-content-padding: #{$size-spacing-l};
}

// Use the spacing from template to separate actions buttons in dialogs
.mdc-dialog__actions {
	gap: $size-spacing-m;
}

mat-dialog-container {
	.mat-toolbar {
		padding: $size-spacing-m $size-spacing-l;
	}

	.mdc-dialog {
		&__content,
		&__actions {
			padding: $size-spacing-m $size-spacing-l;
		}

		&__actions {
			border-top: $size-border-m solid $border-default;
		}
	}
}

// We might need other components than button in the action for dialog (we have a custom button with icon)
.mat-mdc-dialog-actions .mat-button-base + .mat-button-base,
.mat-mdc-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
	margin-left: 0;
}

.dialog--gray {
	background-color: $background-secondary;
}
