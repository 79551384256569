.mat-icon {
	&.mat-icon--s {
		font-size: $uz-font-size-l;
		width: $uz-font-size-l;
		height: $uz-font-size-l;
	}

	&.mat-icon--m {
		font-size: $uz-font-size-xl;
		width: $uz-font-size-xl;
		height: $uz-font-size-xl;
	}
}
