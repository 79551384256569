// Override of the default table style to make them look more custom

html {
	--mat-table-header-headline-size: $uz-font-size;
	--mat-table-row-item-label-text-size: $uz-font-size;
}

table.mat-mdc-table {
	border: $size-border-m solid $border-default;
	border-collapse: collapse;

	thead {
		background-color: $container-static-light-200;

		th {
			border: $size-border-m solid $border-default;
		}
	}

	tbody {
		tr {
			&:hover {
				background-color: $state-layer-dark-hover-low;
			}

			td {
				border: $size-border-m solid $border-default;
			}
		}
	}
}
