@mixin uz-grid-base {
	display: grid;
	grid-template-columns: repeat(12, [col-start] 1fr);
	flex-grow: 1;

	@for $i from 1 through 12 {
		.uz-col-#{$i} {
			grid-column: span $i;
		}
	}
}

.uz-grid {
	gap: $size-spacing-m;

	@include uz-grid-base;
}

.uz-gutterless-grid {
	@include uz-grid-base;
}
