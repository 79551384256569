.drop-box {
	cursor: pointer;
	border: 3px dashed #ccc;
	background-color: rgba(128, 128, 128, 0.1);
	padding: 20px;
	border-radius: 4px;
	color: #333;
	max-height: 250px;
	overflow: auto;

	i {
		color: #666;
		font-size: 64pt;
	}
}
