table.padded-cells {
	th,
	td {
		padding: 15px 10px;
	}
}

.table-container {
	width: 100%;
}

.md-content-with-datatable {
	position: initial !important;
	display: initial;
}

table.datatable {
	border-collapse: collapse;
	margin: 0px auto;
	background: #fff;
	width: 100%;

	&.table-loading {
		opacity: 0;
	}

	&.dt-v-lines {
		> tbody > tr > td {
			border-right: 1px solid #eee;
		}
	}

	&.dt-small-text {
		* {
			font-size: 9pt;
			padding: 5px;
		}
	}

	&.dt-compact {
		td,
		th {
			font-size: 9pt;
			padding: 5px;
		}

		criteria-labels criteria-label > span {
			margin: 0px;
			padding-left: 0px;
		}

		criteria-labels criteria-label > span > span > i {
			margin: 0px 4px;
		}
	}

	&.bordered {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14),
			0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}

	> thead {
		> tr {
			> th,
			> td {
				white-space: nowrap;
				font-weight: bold;
				padding: 15px 10px;
				font-size: 11pt;
				text-align: left;
				background-color: #f5f5f5;
				border: 1px solid #eee;
				border-top: none;
				position: sticky;
				top: 0px;
				z-index: 1;
				&:first-child {
					border-left: none;
				}

				&:last-child {
					border-right: none;
				}

				&[sort] {
					cursor: pointer;

					&:hover,
					&.sorted {
						background-color: #eee;
					}
				}

				&.sorted > *:before {
					content: '\f3d0';
					font-family: Ionicons;
					margin-right: 10px;
				}

				&.sorted.reverse > *:before {
					content: '\f3d8';
				}
			}
		}
	}

	> tbody > tr {
		&[clickable]:hover,
		&[hoverable]:hover {
			> * {
				background: #f5f5f5;
			}
			cursor: pointer;
		}

		&[clickable] {
			&:active,
			&:focus {
				> * {
					background: #eee;
				}
			}
		}

		&[clickable].selected {
			background-color: #00bcd4;
		}

		&.expanded {
			> td {
				border-bottom: 0px;
			}
		}
		&.disabled td,
		&.disabled td > span {
			color: rgba(120, 120, 120, 0.4);
			cursor: not-allowed;
		}
		> td {
			vertical-align: middle;
			padding: 15px 10px;
			border-bottom: 1px solid #eee;
			font-size: 11pt;

			&.expand-padding {
				padding-top: 5px;
				padding-bottom: 10px;
			}

			&:first-child {
				border-left: none;
			}

			&:last-child {
				border-right: none;
			}
		}
	}

	&.small-padding {
		> tbody > tr > td {
			padding: 5px 10px;
		}
	}
	&.no-padding {
		> tbody > tr > td {
			padding: 0px 0px;
		}
	}

	> tfoot > tr > td {
		padding: 15px 10px;
		font-size: 11pt;
		font-weight: bold;
		color: #333;
		border: none;
		/*border-bottom: 1px solid #ccc;*/
	}
}
