@import '../variables/semantic-tokens';

.uz-flex {
	display: flex;
}

.uz-flex-grow {
	flex-grow: 1;
}

.uz-justify-end {
	justify-content: end;
}

.uz-justify-center {
	justify-content: center;
}

.uz-align-center {
	align-items: center;
}

.uz-align-start {
	align-items: flex-start;
}

.uz-justify-space-between {
	justify-content: space-between;
}

.uz-fill {
	width: 100%;
	height: 100%;
}

.uz-w-100 {
	width: 100%;
}

.uz-gap-xs {
	gap: $size-spacing-xs;
}

.uz-gap-m {
	gap: $size-spacing-m;
}

.uz-flex-direction-column {
	flex-direction: column;
}
