@import '../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';

@import './_app/_main/app.scss';

@import './_app/_main/menu/menu.scss';

@import './_app/scss/patches.scss';
@import './_app/scss/app.scss';
@import './_app/scss/calendar.scss';
@import './_app/scss/datatable.scss';
@import './_app/scss/dialog.scss';
@import './_app/scss/dropbox.scss';
@import './_app/scss/form.scss';
@import './_app/scss/requires.scss';
@import './_app/scss/tabs.scss';
@import './_app/scss/views.scss';
@import './_app/scss/color-picker.scss';
@import './_app/scss/color-modifier.scss';
@import './_app/scss/fonts.scss';
