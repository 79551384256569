.icon-add,
.icon-remove,
.primary-color {
	color: var(--primary-color) !important;
}
.secondary-color {
	color: var(--secondary-color) !important;
}
.contrast-primary-color {
	color: var(--black-white-contrast) !important;
}
a {
	text-decoration: underline;
	color: var(--primary-color) !important;
}
.primary-background-color {
	background: var(--primary-color) !important;
}
.secondary-background-color {
	background: var(--secondary-color);
}
md-list-item.primary-color,
.info-message {
	background-color: var(--primary-color-alpha10);
}
.side-nav-bar md-list md-list-item.active,
.round-allocation .platform-list tr.selected {
	background: var(--primary-color-alpha10) !important;
}
.side-nav-bar md-list md-list-item:hover *,
.side-nav-bar md-list md-list-item.active *,
.round-allocation .platform-list tr:hover *,
.round-allocation .platform-list tr.selected * {
	color: var(--primary-color) !important;
}
table.datatable > tbody > tr[clickable].selected {
	background: var(--primary-color);
}
table.datatable > tbody > tr[clickable].selected > * {
	background: var(--primary-color-alpha20) !important;
	cursor: pointer;
}
.fc-unthemed td.fc-today {
	background: var(--primary-color-alpha10) !important;
}
img[user-src],
h2.user-status > span {
	background-color: var(--primary-color) !important;
}
md-toolbar > button {
	color: white !important;
}
md-toolbar > md-select:focus .md-select-value {
	color: white !important;
}
menu button.md-primary {
	color: var(--primary-color) !important;
	background-color: var(--primary-color-alpha10) !important;
	border-left-color: var(--primary-color);
}
md-card-title {
	color: var(--primary-color) !important;
}
criteria-labels:not(.is-embedded)
	criteria-label
	> span.has-dropdown.dropdown-shown {
	background-color: var(--primary-color-alpha10) !important;
	color: var(--primary-color) !important;
}
md-sidenav.detail-window md-toolbar {
	background-color: var(--primary-color-alpha80) !important;
}
table.datatable tbody > tr[clickable]:hover > *,
table.datatable tbody > tr[hoverable]:hover > * {
	background-color: var(--primary-color-alpha10);
}

select:focus,
select:active {
	border-bottom: 2px solid var(--primary-color) !important;
}
.timeline-slider .now {
	background: var(--primary-color);
}
.timeline-slider .now span {
	color: var(--primary-color);
}

.timeline-slider .selection {
	background: var(--primary-color) !important ;
}

.timeline-slider input[type='range']::-webkit-slider-thumb {
	background-color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
}
.timeline-slider::after {
	background-color: var(--secondary-color) !important;
}
.hub-label.selected {
	background-color: var(--secondary-color) !important;
}
