// There is an issue and apparently some styles don't use variables properly so it needs to be hardcoded
// See https://github.com/angular/components/issues/26056
.mat-mdc-unelevated-button.mat-primary {
	--mdc-filled-button-label-text-color: var(--theme-primary-contrast-500);
}

.mat-mdc-unelevated-button.mat-accent {
	--mdc-filled-button-label-text-color: var(--theme-accent-contrast-500);
}

.mat-mdc-raised-button.mat-primary {
	--mdc-protected-button-label-text-color: var(--theme-primary-contrast-500);
}

.mat-mdc-raised-button.mat-accent {
	--mdc-protected-button-label-text-color: var(--theme-accent-contrast-500);
}

// In mat 2, icon buttons, even with primary have no background
.mdc-icon-button.mat-primary {
	background-color: var(--theme-primary-100);
	color: $icon-primary;

	&:hover {
		background-color: var(--theme-primary-200);
	}
}
