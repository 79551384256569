@mixin fillColumn() {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

@mixin fillRow() {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
}

@media screen and (max-width: 600px) {
	md-tabs {
		max-width: 100vw;
	}
}

@mixin toolbar() {
	padding: 0px 20px;
	min-height: 50px;
	height: 50px;
	font-size: 13pt;
	flex-grow: 0 !important;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	@media screen and (max-width: 600px) {
		padding-left: 60px;
		max-width: 100vw;
		overflow: auto;

		button {
			min-width: 40px;
		}

		md-menu {
			flex-grow: 1;
		}
	}

	> span:first-child {
		margin-left: -30px;
		color: white;
		font-size: 12pt;
		text-transform: uppercase;

		ion-icon {
			vertical-align: middle;
			font-size: 24pt;
			margin: 4px;
		}

		@media screen and (max-width: 600px) {
			display: none;
		}
	}

	h2 {
		height: auto !important;
		color: white;
	}

	button.md-button {
		* {
			vertical-align: middle;
			color: white;
		}

		&:disabled * {
			color: rgba(0, 0, 0, 0.26) !important;
		}

		> i {
			font-size: 36px;
		}

		&.md-icon-button {
			font-size: 1.5em;
		}
	}

	&.outset {
		box-shadow: 0px 4px 8px -4px #999;
	}

	@media screen and (max-width: 800px) {
		translate {
			display: none;
		}

		md-toolbar toggle-buttons button {
			min-width: 0px !important;
		}
	}

	md-select {
		margin: 5px;
		color: white;
	}

	i {
		color: white;
	}

	> span {
		color: white;
		padding-left: 20px;
	}

	button.md-button,
	md-select {
		margin: 0px 5px;
	}
}

body {
	> div.body {
		@include fillRow();

		> main {
			@include fillColumn();

			ui-view {
				@include fillColumn();

				screen {
					min-width: 100%;
					max-height: 100vh;

					@include fillColumn();

					@media screen and (max-width: 600px) {
						flex-direction: column;
					}

					> md-toolbar,
					> toolbar > md-toolbar {
						@include toolbar();
					}

					> screen-content,
					.screen-content {
						@include fillRow();
						overflow: auto;

						@media screen and (max-width: 600px) {
							flex-direction: column;
							width: 100vw;
						}

						> nav {
							md-toolbar {
								@include toolbar();
								border-width: 0px;
							}

							@media screen and (max-width: 600px) {
								display: block;
							}
						}

						> section,
						section.screen-content-section {
							width: 100%;
							background: white;
							@include fillColumn();

							> md-toolbar {
								@include toolbar();

								+ * {
									overflow: auto;
									flex-grow: 1;
									background: #fff;
								}
							}
						}
					}

					> screen-content.activity-monitor {
						@include fillColumn();
					}
				}
			}
		}
	}

	&.crud-view {
		.side-nav-bar-list md-list {
			padding: 0px;
		}
	}
}
